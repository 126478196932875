<template>
  <section v-if="!disabled">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <!--begin::Card-->
          <div class="card card-custom gutter-b example example-compact">
            <div class="card-header">
              <h3 class="card-title">Manage Project</h3>
            </div>
            <!--begin::Form-->
            <v-form
              ref="form"
              v-model="valid"
              method="post"
              id="sales_channel_form"
              lazy-validation
            >
              <input type="hidden" name="id" ref="f_id" :value="project.id" />
              <div class="card-body">
                <div
                  :class="`alert alert-${message.color}`"
                  role="alert"
                  v-if="message.show && message.text"
                >
                  {{ message.text }}
                </div>
                <div class="form-group">
                  <v-text-field
                    v-model="project.name"
                    required
                    label="Project name"
                    :rules="[v => !!v || 'Item is required']"
                    ref="f_name"
                    hint="Enter project name"
                  ></v-text-field>
                </div>
                <div class="form-group row">
                  <div class="col-12">
                    <v-switch
                      v-model="project.activated"
                      ref="f_active"
                      label="Activated"
                    ></v-switch>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-12">
                    <v-select
                      v-model="project.user_id"
                      :items="project_users"
                      :item-text="item => item.name + ' ' + item.surname"
                      item-value="id"
                      filled
                      :disabled="!currentUserPersonalInfo.is_admin"
                      label="Project Owner"
                      dense
                    >
                    </v-select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-12">
                    <v-select
                      v-model="project.language_id"
                      :items="locales"
                      :item-text="item => item.name + ' (' + item.code + ')'"
                      item-value="id"
                      filled
                      label="Default locale"
                      dense
                    >
                    </v-select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-12">
                    <v-select
                      v-model="project.source"
                      :items="projectTypesOptions"
                      hint="Pick a project type"
                      filled
                      label="Project type"
                      dense
                    >
                    </v-select>
                  </div>
                </div>
                <hr class="mt-10 mb-10" />

                <div class="form-group row">
                  <div class="col-12">
                    <label>App domains</label>
                    <b-form-tags
                      v-model="project.domains"
                      tag-variant="primary"
                      tag-pills
                      size="lg"
                      separator=" "
                      placeholder="Add your domains, like https://www.mydomain.com"
                    ></b-form-tags>
                  </div>
                </div>
                <hr class="mt-10 mb-10" />
                <div
                  class="form-group row"
                  v-if="currentProject.source == 'mastershop-api'"
                >
                  <div class="col-12">
                    <v-select
                      v-model="project.languages"
                      :items="localizationOptions"
                      hint="Pick language"
                      label="Project languages"
                      multiple
                      persistent-hint
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="card-footer text-right">
                <a
                  v-on:click="deleteItem"
                  href="#"
                  ref="delete"
                  :loading="loading.delete"
                  v-if="project.id > 0"
                  class="btn btn-danger mr-2"
                  >Delete
                </a>
                <v-btn
                  color="primary dark large"
                  ref="submit"
                  :loading="loading.submit"
                  @click="save"
                  >Submit
                </v-btn>
              </div>
            </v-form>
            <!--end::Form-->
          </div>
          <!--end::Card-->
        </div>
      </div>
    </div>
  </section>
  <section v-else>Not autorized access</section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  DELETE_SALES_CHANNEL,
  PULL_SALES_CHANNEL,
  PULL_SALES_CHANNELS,
  PUT_SALES_CHANNEL,
  REFRESH,
  RELOAD_SALES_CHANNEL
} from "@/core/services/store/project.module";
import { RELOAD_MESSAGE } from "@/core/services/store/message.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data: () => ({
    languages: [],
    selectLocalization: null,
    valid: true,
    loading: {
      submit: false,
      delete: false
    },
    rules: {
      required: value => !!value || "Required.",
      counter: value => value.length <= 20 || "Max 20 characters",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      url: value => {
        const pattern = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
        return pattern.test(value) || "Invalid website url.";
      }
    }
  }),
  methods: {
    addLocale() {
      this.languages.push(this.selectLocalization);
    },
    removeLocale(remIndex) {
      this.languages.forEach(function(item, index, object) {
        if (item.key === remIndex) {
          object.splice(index, 1);
        }
      });
    },
    save() {
      // send register request
      if (this.$refs.form.validate()) {
        this.loading.submit = true;

        this.$store
          .dispatch(PUT_SALES_CHANNEL, {
            id: this.$refs.f_id.value,
            data: {
              name: this.project.name,
              user_id: this.project.user_id,
              domains: this.project.domains,
              activated: this.project.activated ? 1 : 0,
              language_id: this.project.language_id,
              languages: this.project.languages
            }
          })
          .then(() => {
            this.loading.submit = false;
            this.$store.dispatch(RELOAD_SALES_CHANNEL);
            this.$store.dispatch(PULL_SALES_CHANNELS);
          });
      }
    },
    deleteItem: function() {
      this.loading.delete = false;

      // send register request
      this.$store
        .dispatch(DELETE_SALES_CHANNEL, this.$refs.f_id.value)
        .then(() => {
          this.loading.delete = false;
          this.$store.dispatch(RELOAD_SALES_CHANNEL).then(() => {
            this.$router.push({ name: "dashboard" });
          });
        });
    },
    reloadData() {
      this.$store.dispatch(PULL_SALES_CHANNEL);
    }
  },
  watch: {
    changeProject() {
      this.reloadData();
    },
    changeCollection() {
      this.reloadData();
    }
  },
  computed: {
    localizationOptions() {
      return Object.entries(
        this.app_settings.languages
      ).map(([key, value]) => ({ text: value, value: key }));
    },
    projectTypesOptions() {
      return Object.entries(
        this.app_settings.project_types
      ).map(([key, value]) => ({ text: value, value: key }));
    },
    changeProject() {
      return this.currentProject.token;
    },
    disabled() {
      return !(
        this.currentUserPersonalInfo.is_admin ||
        this.currentUserPersonalInfo.is_owner
      );
    },
    ...mapState({
      errors: state => state.project.errors,
      message: state => state.message.message,
      hasMessage: state => state.project.hasMessage,
      project: state => state.project.model,
      app_settings: state => state.app_settings,
      project_users: state => state.project.project_users,
      locales: state => state.project.languages,
      current: state => state.project.current_project
    }),
    ...mapGetters(["currentProject", "currentUserPersonalInfo"])
  },
  beforeCreate() {
    this.$store.dispatch(REFRESH);
    this.$store.dispatch(RELOAD_MESSAGE);
  },
  mounted() {
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Project" }]);
  }
};
</script>
